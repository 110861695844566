import React, { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { Box, Button, TextField, Typography, Snackbar, useTheme, Avatar } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import OrganizerSectionToggle from "../../components/OrganizerSectionToggle";
import EmailIcon from "@mui/icons-material/Email";
import axios from "axios";
import OrganizerHeader from "../../components/OrganizerHeader";
import { FileUploader } from "react-drag-drop-files";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";

const Onboard = () => {
  // Check if the screen width is non-mobile
  const isNonMobile = useMediaQuery("(min-width:600px)");

  // State to manage organizer data
  const [organizer, setOrganizer] = useState({});

  // State to manage loading state
  const [loading, setLoading] = useState(true);

  // State to manage profile picture file
  const [profilePicture, setProfilePicture] = useState(null);

  // State to manage snackbar visibility
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // State to manage snackbar message
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [pageOptions, setPageOptions] = useState([
    {
      title: "Organization Header",
      icon: (
        <EmailIcon
          sx={{ 
            color: colors.greenAccent[600], 
            fontSize: "26px" }}
        />
      ),
      toggle: true,
    },
    {
      title: "Organization Intro and Photos",
      icon: (
        <EmailIcon
          sx={{ 
            color: colors.greenAccent[600], 
            fontSize: "26px" }}
        />
      ),
      toggle: true,
    },
    {
      title: "Activity Insights",
      icon: (
        <EmailIcon
          sx={{ 
            color: colors.greenAccent[600], 
            fontSize: "26px" }}
        />
      ),
      toggle: true,
    },
    {
      title: "Upcoming Events",
      icon: (
        <EmailIcon
          sx={{ 
            color: colors.greenAccent[600], 
            fontSize: "26px" }}
        />
      ),
      toggle: true,
    },
    {
      title: "Past Events",
      icon: (
        <EmailIcon
          sx={{ 
            color: colors.greenAccent[600], 
            fontSize: "26px" }}
        />
      ),
      toggle: true,
    },
    {
      title: "Contact Us Form",
      icon: (
        <EmailIcon
          sx={{ 
            color: colors.greenAccent[600], 
            fontSize: "26px" }}
        />
      ),
      toggle: true,
    },
    {
      title: "Sponsors",
      icon: (
        <EmailIcon
          sx={{ 
            color: colors.greenAccent[600], 
            fontSize: "26px" }}
        />
      ),
      toggle: true,
    },
    {
      title: "What We Offer",
      icon: (
        <EmailIcon
          sx={{ 
            color: colors.greenAccent[600], 
            fontSize: "26px" }}
        />
      ),
      toggle: true,
    },
    {
      title: "Address and Maps",
      icon: (
        <EmailIcon
          sx={{ 
            color: colors.greenAccent[600], 
            fontSize: "26px" }}
        />
      ),
      toggle: true,
    },
    {
      title: "Footer",
      icon: (
        <EmailIcon
          sx={{ 
            color: colors.greenAccent[600], 
            fontSize: "26px" }}
        />
      ),
      toggle: true,
    }
  ])
  // Fetch organizer data when the component mounts
  useEffect(() => {
    fetchOrganizerData();
  }, []);

  // Function to fetch organizer data from the API
  const fetchOrganizerData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/organizer/fetch`,
        {
          params: { id: localStorage.getItem("organizerId") },
        }
      );
      setOrganizer(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch organizer data:", error);
      setLoading(false);
    }
  };

  // Function to update organizer data
  const updateOrganizerData = async (values) => {
    try {
      const formData = new FormData();

      // Append all form values to FormData
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      // Append the profile picture file if it exists
      if (profilePicture) {
        formData.append("profile_picture", profilePicture);
      } else {
        console.warn("No profile picture selected.");
      }

      // Send the update request to the API
      const response = await axios.put(
        `${API_BASE_URL}/api/v1/organizer/update?id=${localStorage.getItem(
          "organizerId"
        )}`,
        formData
      );

      // Set snackbar message and show it
      setSnackbarMessage(
        response.data.message || "Organizer updated successfully."
      );
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Failed to update organizer:", error);
      setSnackbarMessage(
        error.response?.data?.message || "Failed to update organizer."
      );
      setOpenSnackbar(true);
    }
  };

  // Handle form submission
  const handleFormSubmit = (values) => {
    updateOrganizerData(values);
  };

  // Handle snackbar close event
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="20px">
      <Header
        title={organizer.name}
        subtitle={organizer.description}
      />
      <OrganizerHeader
        title={"Your landing page settings"}
        subtitle={"Toggle on and off to enable components on your landing page"}
        />
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {
          pageOptions.map((option) => {
            return <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
             <OrganizerSectionToggle
              title={option.title}
              toggle={option.toggle}
              icon={
                option.icon
              }
            />
          </Box>
          })
        }
        

      </Box>

      <OrganizerHeader
        title={"Change organzation details"}
        subtitle={"Fill out the form for your landing page details"}
        />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={organizer || initialValues}
        validationSchema={checkoutSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} style={{marginTop: 25}}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {/* Dynamically generate form fields based on initialValues */}
              {Object.keys(initialValues).map((key) => (
                <TextField
                  key={key}
                  fullWidth
                  variant="outlined"
                  type="text"
                  label={key.replace(/([A-Z])/g, " $1").trim()}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values[key]}
                  name={key}
                  error={!!touched[key] && !!errors[key]}
                  helperText={touched[key] && errors[key]}
                  sx={{ gridColumn: "span 4" }}
                />
              ))}
              <Box gridColumn="span 4">
                <Typography variant="subtitle1" gutterBottom>
                  Profile Picture
                </Typography>
                <FileUploader handleChange={(file)=>{
                  setProfilePicture(file)
                }} 
                style={{ gridColumn: "span 4", marginTop: "15px" }}
                name="file" 
                types={["JPG", "PNG", "GIF","JPEG"]} 
                />

                {/* <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setProfilePicture(e.target.files[0])}
                /> */}
                {profilePicture && (
                  <div>
                  <Typography variant="caption">
                    Selected file: {profilePicture.name}
                  </Typography>
                  <br/>
                  <img 
                    src={URL.createObjectURL(profilePicture)} 
                    style={{marginTop: '25px', objectFit: 'contain'}}
                    width={100} 
                    height={100}/>
                  </div>
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Update Information
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Box>
  );
};

// Initial form values
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  contact: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  country: "",
  postalCode: "",
  website: "",
  challenge: "",
};

// Validation schema for the form
const checkoutSchema = yup.object({
  firstName: yup.string(),
  lastName: yup.string(),
  email: yup.string().email("Invalid email"),
  contact: yup
    .string()
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Phone number is not valid"),
  address1: yup.string(),
  address2: yup.string(),
  city: yup.string(),
  state: yup.string(),
  country: yup.string(),
  postalCode: yup.string(),
  website: yup.string().url("Invalid URL"),
});

export default Onboard;
