import React, { useState, useEffect } from "react";
import { Box, Button, IconButton, Tabs, Tab, TextField, Checkbox, Select,  MenuItem, FormControlLabel, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockTransactions, mockDataContacts, mockDataTickets } from "../../data/mockData";
import EventHeader from "../../components/EventHeader";
import useMediaQuery from "@mui/material/useMediaQuery";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import { useParams, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import Autocomplete from "react-google-autocomplete";
import * as yup from "yup";
import axios from "axios";

const ManageEvent = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [eventData, setEventData] = useState({
      name: "",
      description: "",
      start_date: "",
      end_date: "",
      public: false,
      
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [tabValue, setTabValue] = useState("overview");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const ticketColumns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "ticketId", headerName: "Ticket ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "date",
      headerName: "Register Time",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "user_id",
      headerName: "User ID",
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
    },
    {
      field: "ticket_store_id",
      headerName: "Ticket",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
  ]

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "registrarId", headerName: "Registrar ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "time",
      headerName: "Register Time",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
    },
    {
      field: "zipCode",
      headerName: "Zip Code",
      flex: 1,
    },
  ];


  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";

    const eventSchema = yup.object().shape({
      name: yup.string().required("required"),
      description: yup.string().required("required"),
      startDate: yup.date().required("required"),
      endDate: yup
        .date()
        .required("required")
        .min(yup.ref("startDate"), "End date can't be before start date"),
      placeId: yup.string().required("required"),
      tags: yup.string().required("required"),
      // timetable: yup.string().required("required"),
    });
    

    const handleFormSubmit = async (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("start_date", values.startDate);
      formData.append("end_date", values.endDate);
      formData.append("public", values.isPublic);
      formData.append("place_id", values.placeId);
      formData.append(
        "tags",
        JSON.stringify(values.tags.split(",").map((tag) => tag.trim()))
      );
      // formData.append("timetable", values.timetable);
  
      if (values.images) {
        Array.from(values.images).forEach((image, index) => {
          formData.append(`images[${index}]`, image);
        });
      }
  
      try {
        const token = localStorage.getItem("accessToken");
        const response = await axios.post(
          `${API_BASE_URL}/api/v1/event/update?id=${eventId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `${token}`,
            },
          }
        );
        console.log("Event updated successfully:", response.data);
        setSnackbarMessage("Event updated successfully.");
        setOpenSnackbar(true);
      } catch (error) {
        console.error("Error creating event:", error);
        setSnackbarMessage(
          error.response?.data?.message || "Error creating event."
        );
        setOpenSnackbar(true);
      }
    };

    useEffect(() => {
        fetchEvent();
    }, [eventId]);

    
    const fetchEvent = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/v1/event/fetch/${eventId}`
          );
          setEventData(response.data);
          setLoading(false);
        } catch (err) {
          setError("Failed to fetch event data");
          setLoading(false);
          console.error(err);
        }
    };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={eventData.name} subtitle={eventData.start_date} />
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginRight: "10px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Add Attendees
          </Button>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box>

      
      <Tabs 
        value={tabValue} 
        onChange={handleTabChange} 
        aria-label="manage event"
        TabIndicatorProps={{
          style: {
            display: 'none'
          }
        }}>
      <Tab
        value={"overview"}
        label="Overview"
        style={{
          fontSize: 17, 
          fontWeight: 'bold', 
          color: tabValue == "overview" ? colors.greenAccent[100] : colors.greenAccent[400],}}
      />
      <Tab
        value={"details"}
        label="Details"
        style={{
          fontSize: 17, 
          fontWeight: 'bold', 
          color: tabValue == "details" ? colors.greenAccent[100] : colors.greenAccent[400]}}
      />
      <Tab
        value={"attendees"}
        label="Attendees"
        style={{
          fontSize: 17, 
          fontWeight: 'bold', 
          color: tabValue == "attendees" ? colors.greenAccent[100] : colors.greenAccent[400]}}
      />
      <Tab
        value={"tickets"}
        label="Tickets"
        style={{
          fontSize: 17, 
          fontWeight: 'bold', 
          color: tabValue == "tickets" ? colors.greenAccent[100] : colors.greenAccent[400]}}
      />
      <Tab
        value={"settings"}
        label="Settings"
        style={{
          fontSize: 17, 
          fontWeight: 'bold', 
          color: tabValue == "settings" ? colors.greenAccent[100] : colors.greenAccent[400]}}
      />
    </Tabs>

    {/* Data */}
    {tabValue == "overview" && (
        <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
        marginTop="25px"
       
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="Receiving..."
            subtitle="Attendees"
            progress="0.75"
            increase="+14%"
            icon={
              <EmailIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="Receiving..."
            subtitle="Total Sales"
            progress="0.50"
            increase="+21%"
            icon={
              <PointOfSaleIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="Receiving..."
            subtitle="New Users"
            progress="0.30"
            increase="+5%"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="Receiving..."
            subtitle="Traffic"
            progress="0.80"
            increase="Receiving..."
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Ticket Sales
            </Typography>
          </Box>
          {mockTransactions.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                ${transaction.cost}
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Event Views
            </Typography>
          </Box>
          {mockTransactions.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                ${transaction.cost}
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Geography Based Traffic
          </Typography>
          <Box height="200px">
            <GeographyChart isDashboard={true} />
          </Box>
        </Box>
        </Box>
    )}  

    {tabValue == "attendees" && (
        <Box
        m="20px"
      >
          <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={mockDataContacts}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      </Box>
    )}

    {tabValue == "tickets" && (
          <Box
          m="20px"
        >
            <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={mockDataTickets}
            columns={ticketColumns}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
        </Box>
    )}

    {tabValue == "sponsors" && (
        <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
        marginTop="25px"
      ></Box>
    )}

    {tabValue == "details" && (
      <Formik
      onSubmit={handleFormSubmit}
      initialValues={eventData || {}}
      validationSchema={eventSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
      }) => (
      <form onSubmit={handleSubmit} style={{marginTop: '25px', marginBottom: '25px'}}>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <EventHeader
          title="Event Title *"
          subtitle="Be clear and descriptive with a title that tells people what your event is about."
        />
        <TextField
          fullWidth
          variant="outlined"
          type="text"
          label="Event Title"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.name}
          name="name"
          error={!!touched.name && !!errors.name}
          helperText={touched.name && errors.name}
          sx={{ gridColumn: "span 4" }}
        />

        <EventHeader
          title="Event Description *"
          subtitle="Grab people's attention with a short description about your event. Attendees will see this at the top of your event page. (140 characters max)"
        />
        <TextField
          fullWidth
          variant="outlined"
          type="text"
          multiline
          rows={5}
          label="Description"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.description}
          name="description"
          error={!!touched.description && !!errors.description}
          helperText={touched.description && errors.description}
          sx={{ gridColumn: "span 4" }}
        />

        
        <EventHeader
          title="Date and Time *"
          subtitle="Grab people's attention with a short description about your event. Attendees will see this at the top of your event page. (140 characters max)"
        />

        <TextField
          fullWidth
          variant="outlined"
          type="datetime-local"
          label="Start Date"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.startDate}
          name="startDate"
          error={!!touched.startDate && !!errors.startDate}
          helperText={touched.startDate && errors.startDate}
          sx={{ gridColumn: "span 2" }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          fullWidth
          variant="outlined"
          type="datetime-local"
          label="End Date"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.endDate}
          name="endDate"
          error={!!touched.endDate && !!errors.endDate}
          helperText={touched.endDate && errors.endDate}
          sx={{ gridColumn: "span 2" }}
          InputLabelProps={{ shrink: true }}
        />

        <EventHeader
          title="Event Type *"
          subtitle="Grab people's attention with a short description about your event. Attendees will see this at the top of your event page. (140 characters max)"
        />

<Select
labelId="demo-simple-select-label"
id="demo-simple-select"
value={values.isPublic}
label="Choose Event Type"
variant="outlined"
onBlur={handleBlur}
onChange={handleChange}
name="isPublic"
sx={{ gridColumn: "span 4" }}

>
<MenuItem value={true}>Public</MenuItem>
<MenuItem value={false}>Private</MenuItem>
</Select>

        <EventHeader
          title="Location *"
          subtitle="Grab people's attention with a short description about your event. Attendees will see this at the top of your event page. (140 characters max)"
        />
        <Autocomplete
          style={{ 
            width: "100%", 
            backgroundColor: 'transparent', 
            color: 'darkgrey'}}
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
    onPlaceSelected={(selected, a, c) => {
      const placeAddress = selected.formatted_address
      const placeId = selected.place_id
      setFieldValue("placeId",placeId)
      
    }}

                          
                          options={{
                            types: [],
                            lang: 'en',
                            componentRestrictions: { country: "ca" },
                          }}
    
    placeholder="Search"
    defaultValue=""
  />



        <TextField
          fullWidth
          variant="outlined"
          type="text"
          label="Event Place ID"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.placeId}
          name="placeId"
          error={!!touched.placeId && !!errors.placeId}
          helperText={touched.placeId && errors.placeId}
          sx={{ gridColumn: "span 4" }}
        />
        <TextField
          fullWidth
          variant="outlined"
          type="text"
          label="Tags (comma separated)"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.tags}
          name="tags"
          error={!!touched.tags && !!errors.tags}
          helperText={touched.tags && errors.tags}
          sx={{ gridColumn: "span 4" }}
        />
        <input
          type="file"
          name="images"
          multiple
          onChange={(event) => {
            setFieldValue("images", event.currentTarget.files);
          }}
          style={{ gridColumn: "span 4", marginTop: "15px" }}
        />
      </Box>
      <Box display="flex" justifyContent="end" mt="20px" marginBottom="50px">
        <Button type="submit" color="secondary" variant="contained" size="large" fullWidth>
          Update Event
        </Button>
      </Box>
    </form>
    )}
    </Formik>
    )}
        
      
    </Box>
  );
};

export default ManageEvent;
