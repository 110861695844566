import { useState, useEffect } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import * as yup from "yup";
import axios from "axios";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [events, setEvents] = useState([]);

  const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async (filters = {}) => {
    try {
      const { name, startDate, endDate, tags, page, limit, sortBy, order } =
        filters;
      const query = new URLSearchParams({
        name: name || "",
        start_date: startDate ? startDate.toISOString().split("T")[0] : "", // Format for ISO date
        end_date: endDate ? endDate.toISOString().split("T")[0] : "", // Format for ISO date
        tags: tags || "",
        page: page || 1,
        limit: limit || 10,
        sortBy: sortBy || "start_date",
        order: order || "asc",
      }).toString();
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/event/search?${query}`
      );
      setEvents(
        response.data.events.map((event, index) => ({
          ...event,
          id: event._id, // Use event._id from response as unique ID for each row
        }))
      );
      
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleDateClick = (selected) => {
    const title = prompt("Please enter a new title for your event");
    const calendarApi = selected.view.calendar;
    calendarApi.unselect();

    if (title) {
      calendarApi.addEvent({
        id: `${selected.dateStr}-${title}`,
        title,
        start: selected.startStr,
        end: selected.endStr,
        allDay: selected.allDay,
      });
    }
  };

  const handleEventClick = (selected) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${selected.event.title}'`
      )
    ) {
      selected.event.remove();
    }
  };

  return (
    <Box m="20px">
      <Header title="Calendar" subtitle="Checkout your event calendar" />

      <Box display="flex" justifyContent="space-between">
        {/* CALENDAR SIDEBAR */}
        <Box
          flex="1 1 20%"
          backgroundColor={colors.primary[400]}
          p="15px"
          borderRadius="4px"
        >
          <Typography variant="h5">Events</Typography>
          <List>
            {events.map((event) => (
              <ListItem
                key={event.id}
                sx={{
                  backgroundColor: colors.greenAccent[500],
                  margin: "10px 0",
                  borderRadius: "2px",
                }}
              >
                <ListItemText
                  primary={event.name}
                  secondary={
                    <Typography>
                      {formatDate(event.start_date, {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* CALENDAR */}
        <Box flex="1 1 100%" ml="15px">
          <FullCalendar
            height="75vh"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            initialView="dayGridMonth"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            select={handleDateClick}
            eventClick={handleEventClick}
            eventsSet={(events) => setEvents(events)}
            initialEvents={[
              {
                id: "12315",
                name: "All-day event",
                date: "2022-09-14",
              },
              {
                id: "5123",
                name: "Timed event",
                date: "2022-09-28",
              },
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Calendar;
