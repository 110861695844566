import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Card,
  CardMedia,
  CardActions,
  CardContent,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DatePicker } from "@mui/lab"; // Import DatePicker
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom"; // Import useNavigate for routing

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";

const Events = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const navigate = useNavigate(); // Hook for navigation
  const colors = {
    greenAccent: {
      200: theme.palette.success.light,
      300: theme.palette.success.main,
    },
    blueAccent: {
      700: theme.palette.info.dark,
    },
    primary: {
      400: theme.palette.primary.main,
    },
    grey: {
      100: theme.palette.grey[100],
    },
  };

  const [events, setEvents] = useState([]);
  const [eventShare, setEventShare] = useState(false);
  const [eventShareData, setEventShareData] = useState({
    name: "",
    description: "",
    id: "",
  });
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValues, setSearchValues] = useState(initialValues);
  const [searchInitiated, setSearchInitiated] = useState(false);

  const fetchEvents = async (filters = {}) => {
    try {
      const { name, startDate, endDate, tags, page, limit, sortBy, order, organizerId } =
        filters;
      const query = new URLSearchParams({
        name: name || "",
        organizer_id: organizerId || "",
        start_date: startDate ? startDate.toISOString().split("T")[0] : "", // Format for ISO date
        end_date: endDate ? endDate.toISOString().split("T")[0] : "", // Format for ISO date
        tags: tags || "",
        page: page || 1,
        limit: limit || 100,
        sortBy: sortBy || "start_date",
        order: order || "asc",
        
      }).toString();
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/event/search?${query}`
      );
      setEvents(
        response.data.events.map((event, index) => ({
          ...event,
          id: event._id, // Use event._id from response as unique ID for each row
        }))
      );
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.page);
      setSearchInitiated(true);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleFormSubmit = (values) => {
    setSearchValues(values);
    fetchEvents({ ...values, page: 1 });
  };

  const handlePageChange = (newPage) => {
    fetchEvents({ ...searchValues, page: newPage });
    setCurrentPage(newPage);
  };

  

  const columns = [
    { field: "name", headerName: "Event Name", flex: 1 },
    { field: "start_date", headerName: "Start Date", flex: 1 },
    { field: "end_date", headerName: "End Date", flex: 1 },
    { field: "description", headerName: "Description", flex: 2 },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => (
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate(`/edit/${params.id}`)}
        >
          Edit
        </Button>
      ),
      flex: 1,
    },
  ];

  return (
    <Box m="20px">
      <Header title={`${events.length} EVENTS`} subtitle="View and Manage Your Events" />
      <Dialog onClose={(e)=>{
        setEventShare(false)
      }} open={eventShare}>
        <DialogTitle>
          {"Share event? "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to share <b>{eventShareData.name}</b> with other people?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e)=>{
            setEventShare(false)
          }}>Copy link</Button>
          <Button onClick={(e)=>{
            setEventShare(false)
          }} autoFocus>
            Share
          </Button>
        </DialogActions>
      </Dialog>
      {searchInitiated && (
        <Grid 
          container 
          spacing={5} 
          mt={"20px"}
        >

        {
          events.map((eventItem) => {
            let eventItemImage = ""
            if (eventItem.images != null){
              if (eventItem.images[0] != null){
                eventItemImage = eventItem.images[0]
              }
            }
            return <Grid item xs={4}>
            <Card sx={{ maxWidth: 345 }} elevation={3}>
            <CardMedia
          sx={{ height: 140 }}
          image={eventItemImage == "" ? "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/sponsorBG.JPG" : eventItemImage}
          title={eventItem.name}
        />
        <CardContent>
          <Typography 
            gutterBottom variant="h2" component="div">
            {eventItem.name}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {eventItem.start_date.split("T")[0]} {eventItem.start_date.split("T")[1]}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {eventItem.end_date.split("T")[0]} {eventItem.end_date.split("T")[1]}
          </Typography>
          <br/>
          <Typography variant="body2" color="text.secondary">
            {eventItem.description.substring(0,140)}
          </Typography>
        </CardContent>
        <CardActions style={{ marginBottom: '15px', marginLeft: 5}}>
          <Button 
          sx={{
            backgroundColor: colors.blueAccent[700],
          }}
          size="medium" 
          onClick={(e)=>{
            navigate(`/manage-event/${eventItem.id}`)
          }}>
            <Typography variant="button" color={colors.grey[100]}>
            Manage
          </Typography>
          </Button>
          <Button 
            size="medium" 
            sx={{
              backgroundColor: colors.blueAccent[700],
            }}
            onClick={(e)=>{
            setEventShareData(eventItem)
            setEventShare(true)
          }}>
            <Typography variant="button" color={colors.grey[100]}>
            Share
          </Typography>
          </Button>
        </CardActions>
            </Card>
    </Grid>
          })
        }
        
</Grid>
      )}
      
      {/* <Header title="EVENTS" subtitle="Search and View Events" /> */}

      {/* <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={searchSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Event Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 2" }}
              />
              <DatePicker
                label="Start Date"
                value={values.startDate}
                onChange={(value) => setFieldValue("startDate", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    sx={{ gridColumn: "span 2" }}
                  />
                )}
              />
              <DatePicker
                label="End Date"
                value={values.endDate}
                onChange={(value) => setFieldValue("endDate", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    sx={{ gridColumn: "span 2" }}
                  />
                )}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Tags (comma separated)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.tags}
                name="tags"
                error={!!touched.tags && !!errors.tags}
                helperText={touched.tags && errors.tags}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Search Events
              </Button>
            </Box>
          </form>
        )}
      </Formik> */}

      {/* {searchInitiated && (
        <Box mt="20px" height="600px">
          <DataGrid
            rows={events}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            paginationMode="server"
            onPageChange={handlePageChange}
            page={currentPage - 1}
            rowCount={totalPages * 10}
            components={{
              Toolbar: GridToolbar,
            }}
            sx={{
              "& .MuiDataGrid-root": { border: "none" },
              "& .MuiDataGrid-cell": { borderBottom: "none" },
              "& .name-column--cell": { color: colors.greenAccent[300] },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          />
        </Box>
      )} */}

    </Box>
  );
};

const searchSchema = yup.object().shape({
  name: yup.string(),
  startDate: yup.date().nullable(),
  endDate: yup.date().nullable(),
  tags: yup.string(),
});

const initialValues = {
  name: "",
  startDate: null,
  endDate: null,
  tags: "",
};

export default Events;
