import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import {
  Box,
  Button,
  TextField,
  ImageList,
  ImageListItem,
  Typography,
  Snackbar,
  Select,
  MenuItem,
} from "@mui/material";
import { Formik, FieldArray, Field, Form } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import EventHeader from "../../components/EventHeader";
import axios from "axios";
import Autocomplete from "react-google-autocomplete";
import { useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";

const Events = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [eventImages, setEventImages] = useState([]);
  const [ticketType, setTicketType] = useState("FREE");
  const [ticketStores, setTicketStores] = useState([
    {
      event_id: "",
      organizer_id: "",
      name: "",
      display_name: "",
      start_date: "",
      end_date: "",
      valid_from: "",
      valid_until: "",
      price: 0,
      current_price: 0,
    },
  ]);
  const [eventLocation, setEventLocation] = useState({
    street: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    unit: "",
    name: "",
    lat: 0.0,
    lng: 0.0,
    fullAddress: "",
  });
  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("start_date", values.startDate);
    formData.append("end_date", values.endDate);
    formData.append("public", values.isPublic);
    formData.append("place_id", values.placeId);
    formData.append(
      "tags",
      JSON.stringify(values.tags.split(",").map((tag) => tag.trim()))
    );
    // formData.append("timetable", values.timetable);

    if (values.images) {
      Array.from(values.images).forEach((image, index) => {
        formData.append(`images[${index}]`, image);
      });
    }

    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/event/create`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token}`,
          },
        }
      );
      console.log("Event created successfully:", response.data);
      setSnackbarMessage("Event created successfully.");
      setOpenSnackbar(true);
      navigate("/events");
    } catch (error) {
      console.error("Error creating event:", error);
      setSnackbarMessage(
        error.response?.data?.message || "Error creating event."
      );
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box m="20px">
      <Header title="CREATE EVENT" subtitle="Create a New Event" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={eventSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          handleSubmit,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{ marginTop: "25px", marginBottom: "25px" }}
          >
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <EventHeader
                title="Event Title *"
                subtitle="Be clear and descriptive with a title that tells people what your event is about."
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Event Title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 4" }}
              />

              <EventHeader
                title="Event Description *"
                subtitle="Grab people's attention with a short description about your event. Attendees will see this at the top of your event page. (140 characters max)"
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                multiline
                rows={5}
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 4" }}
              />

              <EventHeader
                title="Date and Time *"
                subtitle="Grab people's attention with a short description about your event. Attendees will see this at the top of your event page. (140 characters max)"
              />

              <TextField
                fullWidth
                variant="outlined"
                type="datetime-local"
                label="Start Date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.startDate}
                name="startDate"
                error={!!touched.startDate && !!errors.startDate}
                helperText={touched.startDate && errors.startDate}
                sx={{ gridColumn: "span 2" }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="datetime-local"
                label="End Date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.endDate}
                name="endDate"
                error={!!touched.endDate && !!errors.endDate}
                helperText={touched.endDate && errors.endDate}
                sx={{ gridColumn: "span 2" }}
                InputLabelProps={{ shrink: true }}
              />

              <EventHeader
                title="Event Type *"
                subtitle="Grab people's attention with a short description about your event. Attendees will see this at the top of your event page. (140 characters max)"
              />

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.isPublic}
                label="Choose Event Type"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                name="isPublic"
                sx={{ gridColumn: "span 4" }}
              >
                <MenuItem value={true}>Public Event - Open to All</MenuItem>
                <MenuItem value={false}>Private Event - Invite Only</MenuItem>
              </Select>

              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isPublic}
                    onChange={() => setFieldValue("isPublic", !values.isPublic)}
                    name="isPublic"
                    style={{ color: "darkgray" }}
                  />
                }
                label="Public"
                sx={{ gridColumn: "span 4" }}
              /> */}

              <EventHeader
                title="Location *"
                subtitle="Grab people's attention with a short description about your event. Attendees will see this at the top of your event page. (140 characters max)"
              />
              <Autocomplete
                style={{
                  width: window.outerWidth / 2,
                  height: 60,
                  borderRadius: 5,
                  padding: 10,
                  borderColor: "lightgrey",
                  borderWidth: 1,
                  backgroundColor: "transparent",
                  color: "darkgrey",
                }}
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                onPlaceSelected={(selected, a, c) => {
                  const placeAddress = selected.formatted_address;
                  const placeId = selected.place_id;
                  setFieldValue("placeId", placeId);

                  let addressComp = eventLocation;
                  addressComp.fullAddress = placeAddress;

                  addressComp.lat = selected.geometry.location.lat();
                  addressComp.lng = selected.geometry.location.lng();

                  for (const component of selected.address_components) {
                    // @ts-ignore remove once typings fixed
                    const componentType = component.types[0];
                    switch (componentType) {
                      case "street_number": {
                        addressComp.street = `${component.long_name}`;
                        break;
                      }
                      case "route": {
                        addressComp.street += component.short_name;
                        break;
                      }
                      case "postal_code": {
                        addressComp.zip = `${component.long_name}`;
                        break;
                      }
                      case "locality":
                        addressComp.city = component.long_name;
                        break;
                      case "administrative_area_level_1": {
                        addressComp.state = component.short_name;
                        break;
                      }
                      case "country":
                        addressComp.country = component.long_name;
                        break;
                      default:
                        break;
                    }
                  }
                  setEventLocation(addressComp);

                  console.log(eventLocation);
                }}
                options={{
                  types: [],
                  lang: "en",
                  componentRestrictions: { country: "ca" },
                }}
                placeholder="Search your event location"
                defaultValue=""
              />

              <Typography
                fullWidth
                variant="body1"
                fontWeight="bold"
                sx={{ gridColumn: "span 4" }}
              >
                {eventLocation.fullAddress === "" ? "" : "Full Address:"}{" "}
                {eventLocation.fullAddress}
              </Typography>

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Event Place ID"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.placeId}
                name="placeId"
                error={!!touched.placeId && !!errors.placeId}
                helperText={touched.placeId && errors.placeId}
                disabled={true}
                sx={{ gridColumn: "span 4" }}
              />

              <div style={{ height: "400px", width: window.innerWidth / 2 }}>
                <GoogleMapReact
                  zoom={16}
                  center={[eventLocation.lat, eventLocation.lng]}
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_API_KEY,
                    language: "en",
                  }}
                >
                  <div lat={eventLocation.lat} lng={eventLocation.lng}>
                    {"Location"}
                  </div>
                </GoogleMapReact>
              </div>

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Tags (comma separated)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.tags}
                name="tags"
                error={!!touched.tags && !!errors.tags}
                helperText={touched.tags && errors.tags}
                sx={{ gridColumn: "span 4" }}
              />

              <EventHeader
                title="Images"
                subtitle="Grab people's attention with a short description about your event. Attendees will see this at the top of your event page. (140 characters max)"
              />

              <FileUploader
                handleChange={(file) => {
                  let eImages = eventImages;
                  eImages.push(file);
                  setEventImages(eImages);
                  setFieldValue("images", eventImages);
                }}
                style={{ gridColumn: "span 4", marginTop: "15px" }}
                name="file"
                types={["JPG", "PNG", "GIF", "JPEG"]}
              />

              {/* <input
                type="file"
                name="images"
                multiple
                onChange={(event) => {
                  setFieldValue("images", event.currentTarget.files);
                }}
                style={{ gridColumn: "span 4", marginTop: "15px" }}
              /> */}

              <ImageList
                sx={{
                  gridColumn: "span 4",
                  width: values.images.length === 0 ? 0 : 500,
                  height: values.images.length === 0 ? 0 : 450,
                }}
                cols={3}
                rowHeight={164}
              >
                {eventImages.map((item) => (
                  <ImageListItem key={item.img}>
                    <img
                      srcSet={URL.createObjectURL(item)}
                      src={URL.createObjectURL(item)}
                      alt={"event"}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
              <EventHeader
                title="Tickets"
                subtitle="Grab people's attention with a short description about your event. Attendees will see this at the top of your event page. (140 characters max)"
              />

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={"FREE"}
                label="Choose Ticket Type"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                name="ticket"
                sx={{ gridColumn: "span 4" }}
              >
                <MenuItem value={"FREE"}>Free Ticket</MenuItem>
                <MenuItem value={"PAID"}>Paid Ticket</MenuItem>
              </Select>

              <FieldArray name="ticketStores">
                {({ push, remove, form }) => (
                  <Box>
                    <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                      Ticket Stores
                    </Typography>
                    {form.values.ticketStores.map((ticket, index) => (
                      <Box
                        key={index}
                        sx={{ border: "1px solid #ccc", p: 2, mb: 2 }}
                      >
                        <TextField
                          fullWidth
                          label="Ticket Name"
                          name={`ticketStores[${index}].name`}
                          value={ticket.name}
                          onChange={form.handleChange}
                          error={
                            form.touched.ticketStores?.[index]?.name &&
                            Boolean(form.errors.ticketStores?.[index]?.name)
                          }
                          helperText={
                            form.touched.ticketStores?.[index]?.name &&
                            form.errors.ticketStores?.[index]?.name
                          }
                        />
                        <TextField
                          fullWidth
                          label="Display Name"
                          name={`ticketStores[${index}].display_name`}
                          value={ticket.display_name}
                          onChange={form.handleChange}
                          error={
                            form.touched.ticketStores?.[index]?.display_name &&
                            Boolean(
                              form.errors.ticketStores?.[index]?.display_name
                            )
                          }
                          helperText={
                            form.touched.ticketStores?.[index]?.display_name &&
                            form.errors.ticketStores?.[index]?.display_name
                          }
                        />
                        {/* Add more fields as required for each ticket store */}
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => remove(index)}
                          sx={{ mt: 1 }}
                        >
                          Remove
                        </Button>
                      </Box>
                    ))}
                    <Button
                      variant="contained"
                      onClick={() =>
                        push({
                          name: "",
                          display_name: "",
                          start_date: "",
                          end_date: "",
                          valid_from: "",
                          valid_until: "",
                          price: 0,
                          current_price: 0,
                          currency: "CAD",
                          terms_and_conditions: "",
                          amount: 100,
                        })
                      }
                      sx={{ mt: 1 }}
                    >
                      Add Ticket Store
                    </Button>
                  </Box>
                )}
              </FieldArray>

              {/* <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Timetable"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.timetable}
                name="timetable"
                error={!!touched.timetable && !!errors.timetable}
                helperText={touched.timetable && errors.timetable}
                sx={{ gridColumn: "span 4" }}
              /> */}
            </Box>
            <Box
              display="flex"
              justifyContent="end"
              mt="20px"
              marginBottom="50px"
            >
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                size="large"
                fullWidth
              >
                Create Event
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Box>
  );
};

const eventSchema = yup.object().shape({
  name: yup.string().required("Event name is required"),
  description: yup.string().required("Description is required"),
  startDate: yup.date().required("Start date is required"),
  endDate: yup
    .date()
    .required("End date is required")
    .min(yup.ref("startDate"), "End date can't be before start date"),
  placeId: yup.string().required("Place ID is required"),
  tags: yup.string().required("Tags are required"),
  images: yup.array(),
  ticketStores: yup.array().of(
    yup.object({
      name: yup.string().required("Ticket name is required"),
      display_name: yup.string().required("Display name is required"),
      start_date: yup.date().required("Start date is required"),
      end_date: yup.date().required("End date is required"),
      valid_from: yup.date().required("Valid from date is required"),
      valid_until: yup.date().required("Valid until date is required"),
      price: yup.number().required("Price is required"),
      current_price: yup.number(),
      currency: yup.string(),
      terms_and_conditions: yup.string(),
      amount: yup
        .number()
        .min(1, "At least one ticket must be available")
        .required("Amount is required"),
    })
  ),
});

const initialValues = {
  name: "",
  description: "",
  startDate: "",
  endDate: "",
  isPublic: false,
  placeId: "",
  tags: "",
  images: [],
  ticketStores: [
    {
      name: "",
      display_name: "",
      start_date: "",
      end_date: "",
      valid_from: "",
      valid_until: "",
      price: 0,
      current_price: 0,
      currency: "CAD",
      terms_and_conditions: "",
      amount: 100,
    },
  ],
};

export default Events;
