import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { TextField, Button, Box, Typography, Paper } from "@mui/material";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";

const Login = ({ handleClose }) => {
  const [organizerId, setOrganizerId] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [challenge, setChallenge] = useState("");
  const [isChallengeSent, setIsChallengeSent] = useState(false);
  const [message, setMessage] = useState("");

  const { orgId } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/api/v1/user/login`, {
        organizer_id: organizerId,
        email: email || undefined,
        phone: phone || undefined,
        challenge: challenge || undefined,
      });

      if (isChallengeSent) {
        const { accessToken } = response.data;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("organizerId", organizerId);
        setMessage("Logged in successfully.");
        fetchOrganizerData()
        // Redirect or perform any further actions as necessary
      } else {
        setIsChallengeSent(true);
        setMessage("Verification code has been sent. Please check your email or phone.");
      }
    } catch (error) {
      setMessage(error.response?.data?.message || "An error occurred.");
    }
  };

  // Function to fetch organizer data from the API
  const fetchOrganizerData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/organizer/fetch`,
        {
          params: { id: localStorage.getItem("organizerId") },
        }
      );
      const organizerData = response.data
      localStorage.setItem("orgName", organizerData.name);
      localStorage.setItem("orgDesc", organizerData.description);
      localStorage.setItem("orgProfile", organizerData.profile_picture);

      handleClose();
    } catch (error) {
      console.error("Failed to fetch organizer data:", error);
      
    }
  };


  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("organizerId");
    setMessage("Logged out successfully.");
    setIsChallengeSent(false);
    setOrganizerId("");
    setEmail("");
    setPhone("");
    setChallenge("");
  };

  useEffect(() => {
    setOrganizerId(orgId)
  }, [])

  return (
    <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="90%"
    >
      <Paper elevation={5} style={{padding: 45}}>
      <form onSubmit={handleSubmit} style={{width: '400px'}}>
        <Box mb={2}>
        <Typography 
          variant="h3" 
          mb={2}
          fontWeight="bold"
          textAlign="center">
          Login to EventGo
        </Typography>
          <TextField
            label="Organizer ID"
            variant="outlined"
            value={organizerId}
            onChange={(e) => setOrganizerId(e.target.value)}
            required
            fullWidth
          />
        </Box>
        {isChallengeSent ? (
          <Box mb={2}>
            <TextField
              label="Verification Code"
              value={challenge}
              onChange={(e) => setChallenge(e.target.value)}
              required
              fullWidth
            />
          </Box>
        ) : (
          <>
            <Box mb={2}>
              <TextField
                type="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
              />
            </Box>
          </>
        )}
        <Button type="submit" size="large" variant="contained" color="primary" fullWidth>
          Login
        </Button>
        {message && <Typography color="error" >{message}</Typography>}
      </form>
      </Paper>
      {localStorage.getItem("accessToken") && (
        <Button
          onClick={handleLogout}
          variant="contained"
          color="secondary"
          fullWidth
        >
          Logout
        </Button>
      )}
    </Box>
  );
};

export default Login;
