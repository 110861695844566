import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Header from "./Header";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";

const EventEdit = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchEvent();
  }, [eventId]);

  const fetchEvent = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/event/fetch/${eventId}`
      );
      setEventData(response.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch event data");
      setLoading(false);
      console.error(err);
    }
  };

  const updateEvent = async (values) => {
    try {
      await axios.put(`${API_BASE_URL}/api/v1/event/update/${eventId}`, values);
      navigate("/events"); // Redirect to the events page or a confirmation page
    } catch (err) {
      setError("Failed to update event");
      console.error(err);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box m="20px">
      <Header title="EDIT EVENT" subtitle={`Editing ${eventData?.name}`} />
      <Formik
        initialValues={eventData || {}}
        enableReinitialize={true} // Ensure the form reinitializes when eventData changes
        validationSchema={yup.object({
          name: yup.string().required("Event name is required"),
          start_date: yup.date().nullable().required("Start date is required"),
          end_date: yup.date().nullable().required("End date is required"),
          description: yup.string(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          updateEvent(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              label="Event Name"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              margin="normal"
            />
            <DatePicker
              label="Start Date"
              value={values.start_date}
              onChange={(value) => setFieldValue("start_date", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={touched.start_date && Boolean(errors.start_date)}
                  helperText={touched.start_date && errors.start_date}
                  margin="normal"
                />
              )}
            />
            <DatePicker
              label="End Date"
              value={values.end_date}
              onChange={(value) => setFieldValue("end_date", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={touched.end_date && Boolean(errors.end_date)}
                  helperText={touched.end_date && errors.end_date}
                  margin="normal"
                />
              )}
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              type="text"
              label="Description"
              name="description"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
              error={touched.description && Boolean(errors.description)}
              helperText={touched.description && errors.description}
              margin="normal"
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Save Changes
            </Button>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default EventEdit;
