import { Box, IconButton, useTheme, Button, Menu, MenuItem, Drawer } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

const Topbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationToggle,setNotificationToggle] = useState(false)
  const [menuItems, setMenuItems] = useState([
    {
      title: "My Account",
      action: "logout",
      type: "Account",
      color: "black",
    },
    {
      title: "Add New",
      action: "logout",
      type: "Account",
      color: 'black',
    },
    {
      title: "Logout",
      action: "logout",
      type: "Account",
      color: 'red',
    }
  ]);

  const setupProfileMenuItems = () => {
    setMenuItems(
      [
        {
          title: "My Account",
          action: "logout",
          type: "Account",
          color: "black",
        },
        {
          title: "Logout",
          action: "logout",
          type: "Account",
          color: 'red',
        }
      ]
    )
  }

  const setupAdditionMenuItems = () => {
    setMenuItems(
      [
        {
          title: "Add New Event",
          action: "/create-event",
          type: "Event",
          color: "black",
        },
        {
          title: "Add New Contact",
          action: "/new-contact",
          type: "Team",
          color: 'black',
        },
        {
          title: "Add Team Member",
          action: "/team",
          type: "Team",
          color: 'red',
        }
      ]
    )
  }

  

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    window.location.href = "/login"
  };

  const notification_list = (anchor) => (
    <Box sx={{width: 300}}></Box>
  )


  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display="flex">
      <Drawer
            anchor={"right"}
            open={notificationToggle}
            onClose={()=>{
              setNotificationToggle(false)
            }}
          >
            {notification_list("right")}
          </Drawer>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton onClick={(e) => {
          setNotificationToggle(true)
        }}>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton onClick={(e) => {
          navigate("/settings")
        }}>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton onClick={(e) => {
          setupProfileMenuItems()
          setAnchorEl(e.currentTarget);
          setMenuOpen(true);
        }}>
          <PersonOutlinedIcon />
        </IconButton>
        <IconButton onClick={(e) => {
          setupAdditionMenuItems()
          setAnchorEl(e.currentTarget);
          setMenuOpen(true);
        }}>
          <AddCircleOutlineIcon />
        </IconButton>
        <Menu
        id="basic-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={(e)=>{
            setMenuOpen(false)
            setAnchorEl(null)
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
        }}
      >
        {
          menuItems.map((itm)=>{
            return <MenuItem color={itm.color} onClick={(e)=>{
              if (itm.action == "logout"){
                handleLogout()
              }
            }}>{itm.title}</MenuItem>
          })
        }
      </Menu>
      </Box>
    </Box>
  );
};

export default Topbar;
