import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Snackbar } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://evtgo.com/">
        Event Go Digital Inc
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.


export default function Signup() {
    
    const theme = useTheme();
    const navigate = useNavigate()
    const colors = tokens(theme.palette.mode);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

    const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";

    const [organizerData, setOrganizerData] = React.useState({
        name: "",
        domain_name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        website: "",
        description: "",
      });

  const handleSubmit = (event) => {
    event.preventDefault();
    
    axios
      .post(`${API_BASE_URL}/api/v1/organizer/create`, organizerData)
      .then((response) => {
        if (response.status !== 201) {
          // Handle error
          console.error("Error creating organizer:", response.data);
          setOpenSnackbar(true)
          setSnackbarMessage("Error creating organizer: " + response.data)
          // Show error message to user
          return false;
        } else {
          setTimeout(() => {
            setOpenSnackbar(true)
            setSnackbarMessage(`Successfully created organizer: ${organizerData.name}`)
            navigate(`/login/${response.data.organizer._id}`)
            //navigateTo(`/organizer/${response.data.organizer._id}`);
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Error creating organizer:", error);
        setOpenSnackbar(true)
        setSnackbarMessage("Error creating organizer: " + error)
        console.log(organizerData)
        // Show error message to user
        // Re-enable the button if there's an error
      });

  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={(e)=>{
            setOpenSnackbar(false)
        }}
        message={snackbarMessage}
      />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              'url("https://images.unsplash.com/photo-1660987979549-28287fbf0d85?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'left',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography 
                component="h1" 
                variant="h2"
                sx={{ fontWeight: 'bold'}}>
              Event Go Landing Page
            </Typography>
            <Box 
                component="form" 
                noValidate 
                onSubmit={handleSubmit} 
                sx={{ mt: 2, }}>
            <Typography 
                component="h1" 
                variant="h5"
                sx={{ margin: 0}}>
              Organization Information
            </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="org-name"
                label="Organization Name"
                name="orgName"
                autoComplete=""
                onChange={(e)=>{
                    let orgD = organizerData
                    orgD.name = e.target.value
                    setOrganizerData(orgD)
                }}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="org-description"
                label="Organization Description"
                name="orgDescription"
                multiline
                rows={5}
                autoComplete=""
                onChange={(e)=>{
                    let orgD = organizerData
                    orgD.description = e.target.value
                    setOrganizerData(orgD)
                }}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="website"
                label="Organization Website"
                name="website"
                autoComplete="url"
                onChange={(e)=>{
                    let orgD = organizerData
                    orgD.website = e.target.value
                    setOrganizerData(orgD)
                }}
                autoFocus
              />

                <Typography 
                component="h1" 
                variant="h5"
                sx={{ mb: 0, mt: 3}}>
              Organization Address
            </Typography>

            <TextField
                margin="normal"
                required
                fullWidth
                id="address-street"
                label="Street Address"
                name="street"
                autoComplete=""
                onChange={(e)=>{
                    let orgD = organizerData
                    orgD.address = e.target.value
                    setOrganizerData(orgD)
                }}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="address-city"
                label="City"
                name="city"
                autoComplete=""
                onChange={(e)=>{
                    let orgD = organizerData
                    orgD.city = e.target.value
                    setOrganizerData(orgD)
                }}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="address-state"
                label="State"
                name="state"
                autoComplete=""
                onChange={(e)=>{
                    let orgD = organizerData
                    orgD.state = e.target.value
                    setOrganizerData(orgD)
                }}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="address-country"
                label="Country"
                name="country"
                autoComplete=""
                onChange={(e)=>{
                    let orgD = organizerData
                    orgD.country = e.target.value
                    setOrganizerData(orgD)
                }}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="address-postalcode"
                label="Postal Code"
                name="postalcode"
                autoComplete=""
                onChange={(e)=>{
                    let orgD = organizerData
                    orgD.postal_code = e.target.value
                    setOrganizerData(orgD)
                }}
                autoFocus
              />

                <Typography 
                component="h1" 
                variant="h5"
                sx={{ mb: 0, mt: 3}}>
              Your Event Go Account
            </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="org-domain"
                label="Event Go Domain"
                name="orgDomain"
                onChange={(e)=>{
                    let orgD = organizerData
                    orgD.domain_name = e.target.value
                    setOrganizerData(orgD)
                }}
                placeholder="Your landing page will be at following: pages.evtgo.com/xxx-xxx"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                placeholder="Please enter your email address"
                onChange={(e)=>{
                    let orgD = organizerData
                    orgD.email = e.target.value
                    setOrganizerData(orgD)
                }}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                autoComplete="phone"
                placeholder="Please enter your phone number"
                onChange={(e)=>{
                    let orgD = organizerData
                    orgD.phone = e.target.value
                    setOrganizerData(orgD)
                }}
                autoFocus
              />
              
              
              
              

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 5, mb: 5, height: 50, fontSize: 17, fontWeight: 'bold' }}
              >
                Create Landing Page
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}