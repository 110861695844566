import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CssBaseline, ThemeProvider, Modal, Box, Button } from "@mui/material";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Onboard from "./scenes/onboard";
import NewMember from "./scenes/new_member";
import Events from "./scenes/events";
import CreateEvents from "./scenes/create_events";
import Geography from "./scenes/geography";
import Calendar from "./scenes/calendar/calendar";
import { ColorModeContext, useMode } from "./theme";
import Login from "./components/Login";
import EventEdit from "./components/EventEdit";
import ManageEvent from "./scenes/manage_event";
import Signup from "./components/Signup";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      setIsLoggedIn(true);
      setIsSidebar(true);
    }
  }, []);

  const handleOpenLogin = () => setIsLoginOpen(true);
  const handleCloseLogin = () => {
    setIsLoginOpen(false);
    const token = localStorage.getItem("accessToken");
    if (token) {
      setIsLoggedIn(true);
      setIsSidebar(true);
    } else {
      setIsLoggedIn(false);
      setIsSidebar(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    setIsLoggedIn(false);
    setIsSidebar(false);
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {isSidebar && <Sidebar />}
          <main className="content">
            {isSidebar && <Topbar setIsSidebar={setIsSidebar}/>}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px",
              }}
            >
              {isLoggedIn ? (
                <Button
                  onClick={handleLogout}
                  variant="contained"
                  color="primary"
                >
                  Logout
                </Button>
              ) : null}
            </div>
            <Modal open={isLoginOpen} onClose={handleCloseLogin}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="absolute"
                top="50%"
                left="50%"
                style={{ transform: "translate(-50%, -50%)" }}
                width={400}
                bgcolor="background.paper"
                border="2px solid #000"
                boxShadow={24}
                p={4}
              >
                <Login handleClose={handleCloseLogin} />
              </Box>
            </Modal>
            <Routes>
              <Route
                path="/"
                element={isLoggedIn ? <Dashboard /> : <Navigate to="/login" />}
              />
              <Route
                path="/login"
                element={
                  isLoggedIn ? (
                    <Navigate to="/" />
                  ) : (
                    <Login handleClose={handleCloseLogin} />
                  )
                }
              />
              <Route
                path="/login/:orgId"
                element={
                  isLoggedIn ? (
                    <Navigate to="/" />
                  ) : (
                    <Login handleClose={handleCloseLogin} />
                  )
                }
              />
              <Route
                path="/create"
                element={<Signup />}
              />
              <Route
                path="/onboard"
                element={isLoggedIn ? <Onboard /> : <Navigate to="/login" />}
              />
              <Route
                path="/team"
                element={isLoggedIn ? <Team /> : <Navigate to="/login" />}
              />
              <Route
                path="/contacts"
                element={isLoggedIn ? <Contacts /> : <Navigate to="/login" />}
              />
              <Route
                path="/events"
                element={isLoggedIn ? <Events /> : <Navigate to="/login" />}
              />
              <Route
                path="/create-event"
                element={
                  isLoggedIn ? <CreateEvents /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/tickets"
                element={isLoggedIn ? <Invoices /> : <Navigate to="/login" />}
              />
              <Route
                path="/new-member"
                element={isLoggedIn ? <NewMember /> : <Navigate to="/login" />}
              />
              <Route
                path="/bar"
                element={isLoggedIn ? <Bar /> : <Navigate to="/login" />}
              />
              <Route
                path="/pie"
                element={isLoggedIn ? <Pie /> : <Navigate to="/login" />}
              />
              <Route
                path="/line"
                element={isLoggedIn ? <Line /> : <Navigate to="/login" />}
              />
              <Route
                path="/faq"
                element={isLoggedIn ? <FAQ /> : <Navigate to="/login" />}
              />
              <Route
                path="/calendar"
                element={isLoggedIn ? <Calendar /> : <Navigate to="/login" />}
              />
              <Route
                path="/geography"
                element={isLoggedIn ? <Geography /> : <Navigate to="/login" />}
              />
              <Route
                path="/edit-event/:eventId"
                element={isLoggedIn ? <EventEdit /> : <Navigate to="/login" />}
              />
              <Route
                path="/manage-event/:eventId"
                element={isLoggedIn ? <ManageEvent /> : <Navigate to="/login" />}
              />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
